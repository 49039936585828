import { createGlobalStyle } from "styled-components";
import NeuropoolWoff from "../../../assets/fonts/neuropool/Neuropol.woff";
import NeuropoolWoff2 from "../../../assets/fonts/neuropool/Neuropol.woff2";
export const GlobalStyle = createGlobalStyle`

     @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap');

      
     @font-face {
      font-family: 'Neuropool';
      src: url(${NeuropoolWoff2}) format('woff2'),
           url(${NeuropoolWoff}) format('woff');
    }
    
    
     *,
      *::after,
      *::before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    
    .App {
        min-height: 100vh;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }
    
    html {
        position:relative;
        overflow-x: hidden;
        font-size: 62.5%;  // 1 rem = 10px;
    }
   
    body {
        color: white;
        position:relative;
        line-height: 1;
        background: url("${(props) =>  props.theme.bgImage.url}");
        background-size: cover;
        font-size: 1.5rem
    }

    h3 {
        font-family: 'Neuropool', sans-serif;
    
    }

    ul {
        list-style: none;
    }

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
    }

    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    a,
    a:visited,
    a:focus,
    a:hover {
        text-decoration: none;
        color: inherit;
    }
    
    .flex-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    
    .flex-container {
    
      display: flex;
      flex-direction: column;
      height: 100%; /* Ensure it takes full height */
  }
      @keyframes flash {
      0%, 100% { opacity: 1; }
      50% { opacity: 0.5; }
      }
    
`;
