import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import backendUrl from "../../config";
import ChartSwitcher from '../SingleCoin/ChartSwitcher';
import { useTheme } from 'styled-components';


const CoinContentDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  color: ${(props) => props.theme.colors.textPrimary}; 
  
  & .currency_sign {
    margin-right: 7px;
  }
  
  & .coin_data {
    flex: 1;
  }
  & div > .coin_top {
    display: flex;
    border-bottom: 1px solid #c1c1c1;
    align-items: center;
    padding: 6px 15px;
    justify-content: space-between;
  }

  & div > .chart_options {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;

    & > span {
      cursor: pointer;
      padding: 2px 5px;
      border: 1px solid transparent;
      transition: all 0.3s ease;
    }

    & > span.selected {
      border: 1px solid ${(props) => props.theme.colors.borderColor};
    }
  }
  &.up {
    & .coin_rate_val {
      color: ${(props) => props.theme.green};
    }
  }

  &.down {
    & .coin_rate_val {
      color: ${(props) => props.theme.red};
    }
  }

  & .coin_top,
  & .chart_options {
    max-width: 270px;
  }

  & .coin_current_rates {
    display: flex;
  }

  & .coin_current_rates > span:first-child {
    margin-right: 20px;
  }

  & .coin_current_rates .coin_details > span {
    margin-right: 15px;
  }

  & .coin_current_rates .coin_details .rate {
    color: ${(props) => props.theme.green};
  }
`;

const CoinName = styled.h3`
  font-family: Neuropool, sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.textPrimary}; 
`;

function SingleCoinContent(props) {

  const theme = useTheme();
  const selectedCoin = useSelector((state) => state.coins.selectedCoin);
  const secondCoin = useSelector((state) => state.coins.secondCoin);
  const coinListData = useSelector((state) => {

      state.coins.coinsList.data
        .filter((Bcoin) => {
          return Bcoin.symbol === selectedCoin.symbol})
        .filter(
          (el) =>
            el.pair ===
            `${selectedCoin.symbol.toLowerCase()}_${secondCoin.toLowerCase()}`
        )
    }
  );
  const selectedCoinIndex = useSelector(
    (state) => state.coins.selectedCoinIndex
  );
  const [chartData, setChartData] = useState([]);
  const [interval, setInterval] = useState("30d");
  const [chartLoading, setChartLoading] = useState(false);
  const [rates, setRates] = useState({
    lastPrice: 0,
    highPrice: 0,
    openPrice: 0,
    lowPrice: 0,
  });

  /*
  *
  *
  * » None 	array 	Candlestick data point detail. The array elements represent the following items respectively:

- Unix timestamp in seconds
- Quote currency trading volume
- Close price
- Highest price
- Lowest price
- Open price
- Base currency trading amount
  *
  * */

  const getCandleStickData = async () => {
    let url = ``,
      start_time;

    if (interval === "1h" || interval === "30m") {
      start_time = moment().subtract(1, "day").unix();
      url += `${backendUrl}/candlesticks?currency_pair=${selectedCoin.curr_a}_${secondCoin}&interval=${interval}&from=${start_time}`;
    } else {
      url += `${backendUrl}/candlesticks?currency_pair=${selectedCoin.curr_a}_${secondCoin}&interval=${interval}`;
    }
    await fetch(`${url}`)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson ? await response.json() : null;
        if (response.ok) {
          const newArr = [];
          data.forEach((tick) => {
            let date;

            if (interval === "1h" || interval === "30m") {
              date = moment.unix(tick[0]).format("MMM Do, h:mm a");
            } else {
              date = moment.unix(tick[0]).format("MMM Do, YY");
            }
            const closePrice = Number(tick[2]);
            const highPrice = Number(tick[3]);
            const lowestPrice = Number(tick[4]);
            const openPrice = Number(tick[5]);
            newArr.push({
              date: date,
              closePrice,
              highPrice,
              lowestPrice,
              openPrice,
              timestamp: tick[0],
            });
          });

          setChartData([...newArr]);
          setChartLoading(false);
        } else {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      })
      .catch((e) => console.log(e));
  };

  const CustomTooltip = ({ active, payload }) => {
    return (
      <div>
        {payload.map((ele, index) => (
          <div key={index}>
            <p style={{ color: theme.colors.textPrimary }}>
              {interval === "1h" ? "Time" : "Date"}: {ele.payload.date}
            </p>
            <p style={{ color: theme.colors.textPrimary }} key={ele.name}>
              Value : {ele.value} $
            </p>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    setChartLoading(true);
    getCandleStickData().catch((err) => console.log(err));
  }, [selectedCoinIndex, secondCoin]);

  useEffect(() => {
    setRates({
      ...rates,
      lowPrice: selectedCoin.low_24h,
      lastPrice: selectedCoin.last,
      highPrice: selectedCoin.high_24h,
      openPrice: selectedCoin.rate,
    });
  }, [selectedCoin, secondCoin]);

  const handleIntervalChange = (int) => {
    setInterval(int);
  };

  useEffect(() => {
    getCandleStickData().catch((err) => console.log(err));
  }, [interval, secondCoin]);
  return (
    <>
      <CoinContentDetails className={`${selectedCoin?.trend} my-second-step-class`}>
        <div className='coin_data'>
          <div className="coin_top">
            <CoinName>
              {selectedCoin.curr_a}/{secondCoin}
            </CoinName>
            <span className="coin_rate">
            {/* <span className="currency_sign">&#36;</span> */}
              <span className="coin_rate_val">{selectedCoin.last}</span>
          </span>
          </div>
          <div className="chart_options my-fourth-step-class">
          <span
            className={interval === "30m" ? "selected" : ""}
            onClick={() => handleIntervalChange("30m")}
          >
            30m
          </span>
            <span
              className={interval === "1h" ? "selected" : ""}
              onClick={() => handleIntervalChange("1h")}
            >
            1h
          </span>

            <span
              className={interval === "1d" ? "selected" : ""}
              onClick={() => handleIntervalChange("1d")}
            >
            1d
          </span>
            <span
              className={interval === "7d" ? "selected" : ""}
              onClick={() => handleIntervalChange("7d")}
            >
            1w
          </span>
            <span
              className={interval === "30d" ? "selected" : ""}
              onClick={() => handleIntervalChange("30d")}
            >
            1M
          </span>
          </div>
          <div className="coin_current_rates">
          <span className={"currency_details"}>
            {selectedCoin.curr_a} / {secondCoin}, {interval}
          </span>
            <span className={"coin_details"}>
            <span>
              O <span className="open_rate rate">{rates.openPrice}</span>
            </span>
            <span>
              H <span className="high_rate rate">{rates.highPrice}</span>
            </span>
            <span>
              L <span className="low_rate rate">{rates.lowPrice}</span>
            </span>
            <span>
              C <span className="current_rate rate">{rates.lastPrice}</span>
            </span>
          </span>
          </div>
        </div>

        <ChartSwitcher />

      </CoinContentDetails>
      {!chartLoading ? (
        <div style={{ width: "100%", height: 400 }} className={'my-third-step-class'}>
          <ResponsiveContainer>
            <AreaChart
              data={chartData}
              margin={{
                top: 10,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={theme.colors.chartBg} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={theme.colors.chartBg} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={theme.colors.chartBg} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={theme.colors.chartBg} stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                axisLine={{ stroke: theme.colors.borderColor }}
                minTickGap={30}
                interval={"preserveStart"}
                tickSize={8}
              />
              <YAxis
                orientation="right"
                tickCount="5"
                padding={{ bottom: 10 }}
                axisLine={{ stroke: theme.colors.borderColor }}
                tick={{ stroke: theme.colors.borderColor, strokeWidth: 0 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="closePrice"
                stroke={theme.colors.chartBg}
                fillOpacity={1}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div
          style={{
            height: "300px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <ClipLoader color={theme.colors.borderColor} />
        </div>
      )}
    </>
  );
}

export default SingleCoinContent;
