import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../../assets/css/icons.css";
import { useSelector } from "react-redux";
import moment from "moment";
import backendUrl from "../../config";

const TradingHistory = styled.div`
  

  background-color: ${(props) => props.theme.colors.bgLayoutPart};
  box-shadow: ${(props) => props.theme.shadow};
  padding: 2rem 2rem 2rem 3rem;
  font-family: ${(props) => props.theme.fonts.secondaryFont};
  flex: 1;
  @media (max-width: 1920px) {
      height: 55%;
  }
  & .trading_wrapper {
    overflow: hidden;
    max-height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: .5rem 1rem;
    
    
     @media (max-width: 1920px) {
      max-height: 370px;
  }
  }

  & .trade_entry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: .3rem;
    font-size: 1.35rem;
    border-bottom: 1px solid rgba(255,255,255,.15);
    & > span {
      width: 33.33%;
    }
  }

  & .trade_entry.sell {
    & .trade_price {
      color: ${(props) => props.theme.red};
    }
  }

  & .trade_entry.buy {
    & .trade_price {
      color: ${(props) => props.theme.green};
    }
  }

  & h4 {
    font-family: ${(props) => props.theme.fonts.mainFont};
    font-size: 2rem;
    font-weight: 400;
    text-transform: uppercase;
  }
  & .trading_header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    border-top: 1px solid ${(props) => props.theme.colors.borderColor};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
    margin: 5px 0;

    & > span {
      width: 33.33%;
    }
  }
`;

function SingleCoinTrades(props) {
  const selectedCoin = useSelector((state) => state.coins.selectedCoin);
  const [trades, setTrades] = useState([]);
  let intervalID = null;

  const getCoinTrades = async () => {
    let url = `${backendUrl}/coinTrades`;

    const res = await fetch(`${url}`);
    const data = await res.json();
    if (res.ok) {
      if (data.length > 2) {
        const _trades = [];
        data.forEach((entry) => {
          const formattedDate = moment
            .unix(entry.create_time)
            .format("h:mm:ss");

          _trades.push({ date: formattedDate, ...entry });

          setTrades(_trades);
        });
      }
    } else {
      throw new Error("failed to fetch coins");
    }
  };

  useEffect(() => {
    getCoinTrades().catch((err) => console.error(err));
  }, [selectedCoin]);
  return (
    <TradingHistory>
      <h4>Trading history</h4>
      <div className="trading_header">
        <span>Time</span>
        <span>Sell Price</span>
        <span>Total (USDT)</span>
      </div>
      <div className="trading_wrapper">
        {trades.length > 0 &&
          trades.map((trade) => {
            return (
              <div className={`trade_entry ${trade.side}`} key={trade.id}>
                <span className="trade_time">{trade.date}</span>
                &nbsp;
                <span className="trade_price">{trade.price}</span>
                &nbsp;&nbsp;
                <span className="trade_amount">{trade.amount}</span>
              </div>
            );
          })}
      </div>
    </TradingHistory>
  );
}

export default SingleCoinTrades;
