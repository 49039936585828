import { configureStore } from "@reduxjs/toolkit";
import { coinsSlice } from "./reducers/coinsSlice";
import { pairsSlice } from "./reducers/pairsSlice";

const reducer = {
  coins: coinsSlice,
  pairs: pairsSlice,
};

const store = configureStore({ reducer });

export default store;
