import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import backendUrl from "../../config";

export const fetchPairs = createAsyncThunk("fetchPairsResults", async () => {
  const res = await fetch(`${backendUrl}/pairs`);

  const data = await res.json();

  if (res.ok) {
    return data;
  } else {
    throw new Error("failed to fetch coins");
  }
});

//  const getEuroCurr = createAsyncThunk("getUsdToEuro", async () => {
//   const url =
//     "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usdt/eur.json";
//   const res = await fetch(`${url}`);
//   const data = await res.json();
//   if (res.ok) {
//     return data.eur;
//   } else {
//     throw new Error("failed to fetch currency");
//   }
// });

const pairsSlice = createSlice({
  name: "pairs",
  initialState: {
    pairsList: {},
    fetchingPairs: false,
  },
  reducers: {
    hydrate: (state, action) => {
      return action.payload;
    },
    setDisplayedCoins: (state, action) => {
      let coins = action.payload;
      state.displayedCoins.push(...coins);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPairs.fulfilled, (state, action) => {
      const pairs = action.payload.data;

      state.pairsList = action.payload;

      if (state.fetchingPairs) {
        state.fetchingPairs = false;
      }
    });

    builder.addCase(fetchPairs.pending, (state, action) => {
      state.pairsList = {};
      if (!state.fetchingPairs) {
        state.fetchingPairs = true;
      }
    });
  },
});

const { reducer, actions } = pairsSlice;

export { reducer as pairsSlice };
