import React from "react";
import styled from "styled-components";

const GateIoLink = styled.div`
  background-color: ${(props) => props.theme.colors.bgLayoutPart};
  box-shadow: 3px 5px 11px rgba(0, 0, 0, 0.5);
  justify-content: space-between;
  padding: 10px 10px 15px 30px;
  margin-top: 20px;
  & h4 {
    font-family: Neuropool, sans-serif;

    font-weight: 400;
    font-size: 22px;
    width: 25%;
    padding: 4px 10px;
    border-bottom: 1px solid #c1c1c1;
    text-transform: uppercase;
  }

  & a {
    margin-top: 20px;
    display: block;
    font-size: 22px;
    color: #c1c1c1;
  }

  & a:hover {
    text-decoration: underline;
  }
`;

function SingleCoinLink(props) {
  return (
    <GateIoLink>
      <h4>Trading</h4>
      <a href="https://www.gate.io/de/trade/MLT_USDT" target="_blank" rel="noreferrer">
        https://www.gate.io/de/trade/MLT_USDT
      </a>
    </GateIoLink>
  );
}

export default SingleCoinLink;
