import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { setGraphType } from '../../redux/reducers/coinsSlice'
import { useSelector } from "react-redux";
import styled from 'styled-components'


const ChartSwitcherWrapper = styled.div`
  
  display:flex;
  justify-content: end;
  margin-bottom: 1.5rem;
  
  button {
    outline: none;
    border: none;
    background-color: ${(props) => props.theme.colors.buttonBg};
    color: ${(props) => props.theme.colors.textPrimary};
    font-family: ${(props) => props.theme.fonts.mainFont};
    cursor: pointer;
    font-size: 1.4rem;
    border:  ${(props) => props.theme.borderStyle};
    border-radius: ${(props) => props.theme.borderRadius};
    padding: .75rem 1rem;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
  
  button.active {
    background-color: ${(props) => props.theme.colors.bgSelected};
  }
  
  p {
    font-style: italic;
    font-weight: 300;
    font-family: ${(props) => props.theme.fonts.secondaryFont};
    margin-bottom: .75rem;
  }
`

const ChartSwitcher = () => {
  const [activeChart, setActiveChart] = useState(useSelector((state) => state.coins.graphType));
  const dispatch = useDispatch();


  useEffect(() => {
      dispatch(setGraphType(activeChart))
  }, [activeChart]);


  const handleSetActiveChart = (style) =>
  {
    console.log(activeChart)
    setActiveChart(style)
    // dispatch(setGraphType({activeChart}))
  }
  return (
    <ChartSwitcherWrapper>
      <div className={'my-fifth-step-class'}>
        <p>Change chart style</p>
        <button onClick={() => handleSetActiveChart('exroom')} className={activeChart === 'exroom' ? 'active' : ''}>Exchangeroom chart</button>
        <button onClick={() => handleSetActiveChart('tradingview')} className={activeChart === 'tradingview' ? 'active' : ''}>Tradingview chart</button>
      </div>

    </ChartSwitcherWrapper>
  );
};

export default ChartSwitcher;
