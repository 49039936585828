export const nFormatter = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};

export const determineClassName = (percentDiff) => {
  const ranges = [0.15, 0.45, 0.85];

  if (percentDiff >= 0) {
    if (percentDiff < ranges[0]) return "";
    if (percentDiff < ranges[1]) return "up-1";
    if (percentDiff < ranges[2]) return "up-2";
    return "up-3";
  } else {
    const absoluteDiff = Math.abs(percentDiff);
    if (absoluteDiff < ranges[0]) return "";
    if (absoluteDiff < ranges[1]) return "down-1";
    if (absoluteDiff < ranges[2]) return "down-2";
    return "down-3";
  }
};


export const updateTickerInfo = (state, pairs, index) => {
  // Your logic from addTickerInfo goes here, using state, pairs, and index
  // Example:
  // const rawState = current(state);
  // ...
}
