import React, { useContext, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from 'react-redux';
import { light, dark } from "./assets/theme";
import { GlobalStyle } from "./assets/GlobalStyles";
import { ThemeContext } from "../../contexts/themecontext.js";
import Header from '../Header'
import { Outlet } from "react-router-dom";
import TickerUpdater from '../TickerUpdater/TickerUpdater'
import OnboardingContext from '../../contexts/OnboardingContext';
import Onboarding from '../Onboarding/Onboarding'
import styled from 'styled-components'

const OnboardingWrapper = styled.div`
    text-align: left;
    
    h3 {
    text-align: center;
    }
  & p {
  font-size: 1.9rem;
  }
`

export function Layout({ children }) {
  const { th } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const hasCompletedOnboarding = localStorage.getItem('hasCompletedOnboarding');
  const [startOnboarding, setStartOnboarding] = useState(!hasCompletedOnboarding);


  const handleStartOnboarding = () => {
    console.log('poceli')
    setStartOnboarding(true);
  };

  const handleEndOnboarding = () => {
    localStorage.setItem('hasCompletedOnboarding', 'true');
    setStartOnboarding(false);
  }

  const fetchingCoins = useSelector((state) => state.coins.fetchingCoins);
  const coins = useSelector((state) => state.coins.coinsList);
  const steps = [
    {
      target: 'body',
      content: (
        <OnboardingWrapper>
          <h3>MILC EXCHANGE ROOM</h3>
          <br/>
          <p>Welcome to the Exchange Room Platform. </p>
          <p>This multi step guide will serve as a quick introduction to the platform features.</p>
        </OnboardingWrapper>
      ),
      placement: 'center',
      disableBeacon: true,
      styles: {
        options: {
          width: 700
        }
      }
    },
    {
      target: '.my-first-step-class',
      content: (<OnboardingWrapper>
        <h3>COIN LIST</h3>
        <br/>
        <p>This list features most popular coins from coinbase.</p>
        <br/>
        <p>You can click on a coin to feature it, clicking on a star will add it to favorites, or you can change second currency from the dropdown!</p>
      </OnboardingWrapper>),
      placement: 'right',
      styles: {
        options: {
          width: 550
        }
      }
    },
    {
      target: '.my-second-step-class',
      content: (<OnboardingWrapper>
        <h3>SELECTED COIN DETAILS</h3>
        <br/>
        <p>Here you can see additional details about selected coin.</p>
      </OnboardingWrapper>),
      placement: 'bottom',
      offset: 0
    },
    {
      target: '.my-third-step-class',
      content: (<OnboardingWrapper>
        <h3>CHART</h3>
        <br/>
        <p>Chart graphically presents the price movement of the selected coin relative to the currency chosen.</p>
      </OnboardingWrapper>),
      placement: 'bottom'
    },
    {
      target: '.my-fourth-step-class',
      content: (<OnboardingWrapper>
        <h3>CHART OPTIONS</h3>
        <br/>
        <p>You can pick time ranges for the chart display here.</p>
      </OnboardingWrapper>),
      placement: 'bottom'
    },
    {
      target: '.my-fifth-step-class',
      content: (<OnboardingWrapper>
        <h3>CHANGE CHART TYPE</h3>
        <br/>
        <p>Or toggle the style of the chart.</p>
      </OnboardingWrapper>),
      placement: 'bottom'
    },
    {
      target: '.my-sixth-step-class',
      content: (<OnboardingWrapper>
        <h3>WALLET CONNECTION</h3>
        <br/>
        <p>If you chose to, you can connect your wallet with the platform.</p>

      </OnboardingWrapper>),
      placement: 'bottom'
    },
    {
      target: '.my-seventh-step-class',
      content: (<OnboardingWrapper>
        <h3>START THE TOUR</h3>
        <br/>
        <p>You can always watch the tour again if you get stuck.</p>
        <br/>
        <p>That's it. Have fun using the platform!</p>
      </OnboardingWrapper>),
      placement: 'bottom'
    }
  ];


  return (
    <OnboardingContext.Provider value={{ startOnboarding: handleStartOnboarding }}>
      <ThemeProvider theme={th === "light" ? light : dark}>
        <GlobalStyle />

        <Header />
        <TickerUpdater />
        <Outlet />
        {Object.keys(coins).length > 0 && <Onboarding steps={steps} onStart={() => {
          console.log('Starting...');
        }} onComplete={handleEndOnboarding} autoStart={startOnboarding}/>}

      </ThemeProvider>
    </OnboardingContext.Provider>
  );
}
