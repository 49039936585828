import React, { useEffect, useState } from "react";

export const ThemeContext = React.createContext('dark');

export const ThemeProvider = ({ children }) => {
  const [th, setTheme] = useState("dark");

  useEffect(() => {
    setTheme(localStorage.getItem("theme") ? localStorage.getItem("theme") : '');
    console.log('temica', th, localStorage.getItem("theme"))
  }, []);

  const toggleTheme = () => {
    console.log('jel smo ovde')
    if (th === "dark"){
      setTheme("light");
      localStorage.setItem("theme", "light");
    } else {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    }
  };

  return <ThemeContext.Provider value={{ th, toggleTheme }}>{children}</ThemeContext.Provider>;
};
