import React from "react";
import Select from "react-select";
import "../../assets/css/icons.css";
import "./select.css";

const MyComponent = React.memo(function MyComponent({ pairs, handleSetSecondCoin }) {
  const options = pairs.map((el) => {
    // console.log(el)
    const obj = {};

    return { ...obj, value: el, label: el };
  });
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#216682",
      cursor: "pointer",
      color: "white",
      padding: 0
    }),
    container: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,

      color: "white",
      fontSize: 10,
      padding: 0,
    }),

    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      border: "1px solid white",
      outline: "none",
      minHeight: 20,
      backgroundColor: "#216682",
      width: 100,
      padding: 0,
      cursor: "pointer",
    }),

    indicatorSeparator: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,

      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      paddingRight: 0,
      paddingLeft: 0,
    }),

    valueContainer: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      backgroundColor: "#216682",
      color: "white",
      width: 50,
      paddingRight: 0,
      padding: 1
    }),

    singleValue: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      backgroundColor: "#216682",
      color: "white",
    }),

    menu: (provided) => ({
      ...provided,
      border: "1px solid white",
      backgroundColor: "#216682",
      width: 100,
    }),
  };
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        styles={customStyles}
        options={options}
        placeholder=""
        defaultValue={options[0]}
        onChange={handleSetSecondCoin}
        getOptionLabel={(e) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={`icon-16 icon-16-${e.label.toLowerCase()}`} style={{ width: 16, height: 16 }}></div>
              <span style={{ marginLeft: 5 }}>{e.label}</span>
            </div>
          );
        }}
      />
    </div>
  );
});

export default MyComponent;
