import React, { useEffect, useState } from "react";
import Wrapper from "../components/Layout/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  addExtraDetails,
  fetchCoinsThunk,
  getEuroCurr,
} from "../redux/reducers/coinsSlice";
import {
  setDisplayedCoins,
  setSelectedCoin,
} from "../redux/reducers/coinsSlice";
import SingleCoinHeader from "../components/SingleCoin/SingleCoinHeader";
import SingleCoinContent from "../components/SingleCoin/SingleCoinContent";
import SingleCoinTrades from "../components/SingleCoin/SingleCoinTrades";
import SingleCoinLink from "../components/SingleCoin/SingleCoinLink";
import SmartContract from "../components/MltContracts";
import backendUrl from "../config";

function MltCoinPage() {
  const dispatch = useDispatch();
  const coinsList = useSelector((state) => state.coins.coinsList);
  const coins = useSelector((state) => state.coins.displayedCoins);
  const [coinLoaded, setCoinLoaded] = useState(false);
  const [coinClassName, setCoinClassName] = useState("");
  const [refreshInterval, setRefreshInterval] = useState(
    Math.floor(Math.random() * (8000 - 6000 + 1)) + 6000
  );
  const [coinUpdated, setCoinUpdated] = useState(false);

  let intervalID = null;

  const getCoinDetails = async () => {
    const url = `${backendUrl}/coinPairs/MLT_USDT`;
    const res = await fetch(`${url}`);
    const data = await res.json();
    if (res.ok) {
      dispatch(addExtraDetails({ coin: data, index: 0 }));
    } else {
      throw new Error("failed to fetch coin");
    }
  };

  function intervalManager(flag, time) {
    if (flag) {
      intervalID = setInterval(async () => {
        await getCoinDetails().catch(console.error); // API call
        clearInterval(intervalID);
      }, time);
    } else {
      clearInterval(intervalID);
    }
    setCoinUpdated(!coinUpdated);
  }

  useEffect(() => {
    if (Object.keys(coinsList).length === 0) {
      dispatch(fetchCoinsThunk());
      dispatch(getEuroCurr());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(coinsList).length > 0) {
      const coin = coinsList.data.find((item, index) => item.symbol === "MLT");
      dispatch(setDisplayedCoins([coin]));
      dispatch(setSelectedCoin(0));
      getCoinDetails().catch((err) => console.error(err));
    }
  }, [coinsList]);

  useEffect(() => {
    if (!coinLoaded) {
      getCoinDetails().catch(console.error);
      setCoinLoaded(true);
    }
  }, [getCoinDetails]);

  useEffect(() => {
    const int = Math.floor(Math.random() * (8000 - 6000 + 1)) + 6000;
    intervalManager(false);
    if (coinLoaded) {
      setRefreshInterval(int);
      intervalManager(true, refreshInterval);
    }
    setTimeout(() => {
      setCoinClassName("");
    }, 1000);
  }, [coins]);

  return (
    <Wrapper fullHeight={'90vh'}>
      <div className={'col-3-of-4 flex-container'}>
              <SingleCoinContent />
              <SingleCoinLink />
      </div>
      <div className={'col-1-of-4 flex-container'}>
              <SingleCoinTrades />
              <SmartContract />
      </div>
      {/*<div className="flex-wrap">*/}
      {/*  <div*/}
      {/*    style={{*/}
      {/*      width: "100%",*/}
      {/*      display: "flex",*/}
      {/*      flexDirection: "row",*/}
      {/*      height: "100%",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <div*/}
      {/*      style={{ width: "100%", display: "flex", flexDirection: "column" }}*/}
      {/*    >*/}
      {/*      <SingleCoinContent />*/}
      {/*      <SingleCoinLink />*/}
      {/*    </div>*/}
      {/*    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>*/}
      {/*      <SingleCoinTrades />*/}
      {/*      <SmartContract />*/}
      {/*    </div>*/}

      {/*  </div>*/}
      {/*</div>*/}
    </Wrapper>
  );
}

export default MltCoinPage;
