import React from "react";
import styled from "styled-components";

const MltInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.mainFont};
  text-transform: capitalize;
  font-size: 1.4rem;
 
`;

const LogoWrapper = styled.img`
  width: ${(props) => (props.width ? props.width : "auto")};
  margin-right: 1rem;
`;

export default function MltInfo({ name }) {
  return (
    <MltInfoWrapper>
      <LogoWrapper src={`/images/3d-logo-240.png`} width={45} />
      {name ?? <p>{name}</p>}
    </MltInfoWrapper>
  );
}
