import React, { useEffect, useState } from "react";
import Wrapper from "../components/Layout/Wrapper";
import CoinList from "../components/CoinList/CoinList";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoinsThunk, getEuroCurr, fetchCoinTickersThunk } from "../redux/reducers/coinsSlice";
import { setDisplayedCoins, updateDisplayedCoins, updateFavoriteFlags } from "../redux/reducers/coinsSlice";
import SingleCoinContent from "../components/SingleCoin/SingleCoinContent";
import TwitterFeed from "../components/TwitterFeed";
import { fetchPairs } from "../redux/reducers/pairsSlice";
import { hydrateFavoritesFromLocalStorage } from '../redux/reducers/coinsSlice'


function ListPage() {
  const dispatch = useDispatch();
  const coinsList = useSelector((state) => state.coins.coinsList);
  const fetchingCoins = useSelector((state) => state.coins.fetchingCoins);
  const [coinsCount, setCoinsCount] = useState(0)

  useEffect(() => {
    if (Object.keys(coinsList).length === 0) {
      dispatch(fetchCoinsThunk());
      // dispatch(getEuroCurr());
      dispatch(fetchCoinTickersThunk())
      dispatch(fetchPairs());

    }
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(coinsList).length > 0) {
      const coins = coinsList.data.slice(0,500);
      dispatch(setDisplayedCoins(coins));

      dispatch(hydrateFavoritesFromLocalStorage())
      dispatch(updateDisplayedCoins());
      dispatch(updateFavoriteFlags());

      setCoinsCount(prevState => prevState + 500)
    }

  }, [coinsList]);




  return (
    <Wrapper fullHeight={'90vh'}>
      <>
        {fetchingCoins ? (
          <p>Loading</p>
        ) : (
          <>
            <div
              className="col-1-of-4" style={{overflow:'hidden'}}>
                   <CoinList updateDisplayedCoins={''} coinsCount={coinsCount}/>
            </div>
            <div className="col-2-of-4 flex-wrap">
              <SingleCoinContent />
            </div>
            <div className="col-1-of-4 flex-container">
              <TwitterFeed />
              {/*<NewsFeed />*/}
            </div>
          </>
        )}
      </>
    </Wrapper>
  );
}

export default ListPage;
