import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import '../../assets/css/icons.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTickerInfo,
  setSecondCoinAction,
  setSelectedCoin,
  setAddFavorite,
  setDisplayedCoins,
  updateDisplayedCoins,
  updateFavoriteFlags
} from '../../redux/reducers/coinsSlice';
import MyComponent from './select'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';


import ClipLoader from 'react-spinners/ClipLoader';
import './coindetails.css';
const FilledStar = styled(AiFillStar)`
  color: ${(props) => props.theme.red};
`

const EmptyStar = styled(AiOutlineStar)`
  color: ${(props) => props.theme.colors.textPrimary};
`
const CoinDetails = styled.div`
  background-color: ${(props) => props.theme.colors.bgLayoutPart};
  display: flex;
  font-family: ${(props) => props.theme.fonts.secondaryFont};
  justify-content: space-between;
  background-image: linear-gradient(#ccc, #ccc);
  background-size: 95% 1px;
  background-position-y: 102%;
  background-position-x: center;
  background-repeat: no-repeat;
  padding: .5rem 1rem;
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
  }

  &.selected {
    background-color: ${(props) => props.theme.colors.bgSelected}; 
  }
  
  > div {
    display: flex;
    min-width: 33.33%;
  }

  & .coin_name {
    flex-direction: column;
    align-items: start;
    justify-content: center;
    font-weight: 600;
  }

  & .coin_name > div {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
  }
  & .coin_name > span {
    font-size: 1.3rem;
    color: #c1c1c1;
  }
  & .coin_name .icon-16 {
    margin-right: .7rem;
    width: 16px;
    height: 16px;
  }

  & .coin_name h3 {
    color: ${(props) => props.theme.colors.textPrimary};
    font-size: 1.6rem;
    letter-spacing: 1px;
    font-weight: 600;
  }

  & .coin_growth {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
  }

  & .coin_rate,
  & .coin_growth {
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  &.favorite {
    background-color: ${(props) => props.theme.colors.bgFavorite };
  }
  
  & .coin_pair {
    min-width: unset;
  } 

  & .coin_rate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  & .coin_rate .coin_rate_eur {
    font-size: 14px;
  }

  &.up {
    .coin_rate_usdt {
      color: ${(props) => props.theme.green};
    }

    .coin_percent_growth {
      color: ${(props) => props.theme.green};
    }
  }
  
  &.up-1 {
    background-color: rgba(92, 229, 110, 0.1);
  }
  &.up-2 {
    background-color: rgba(92, 229, 110, 0.4);
  }
  &.up-3 {
    background-color: rgba(92, 229, 110, 0.8);
  }

  &.down-1 {
    background-color: rgba(255, 64, 64, 0.1);
  }
  &.down-2 {
    background-color: rgba(255, 64, 64, 0.4);
  }
  &.down-3 {
    background-color: rgba(255, 64, 64, 0.8);
  }

  &.percent_down {
    background-color: red;
  }
  
  &.down {
    .coin_rate_usdt {
      color: ${(props) => props.theme.red};
    }

    .coin_percent_growth {
      color: ${(props) => props.theme.red};
    }
  }
  
  &.up-1, &.up-2, &.up-3, &.down-1, &.down-2, &.down-3 {
    animation: flash 0.35s 3; /* This will make the flash effect occur 3 times over 1.5 seconds total */
}

  
`;

const CoinListDetails = ({ index, style, isScrolling, isSelected }) => {
  // // const coins = useSelector((state) => state.coins.displayedCoins, shallowEqual);
  const coin = useSelector((state) => {
    const coins = state.coins.displayedCoins;

    return coins && coins.length > index ? coins[index] : null;
  });
  //

  //
  // const pairsFromState = useSelector(state => state.pairs.pairsList[coin?.symbol]);
  // // States
  const [coinClassName, setCoinClassName] = useState('');
  const [secondCoin, setSecondCoin] = useState('USDT');
  //
  // // Redux states and actions
  const dispatch = useDispatch();
  // const selectedCoinIndex = useSelector(state => state.coins.selectedCoinIndex);
  const pairs = useSelector(state => {
    const pairs = state.pairs.pairsList

    const arr = []
    if(coin && pairs && pairs[coin.symbol]) {
      pairs[coin.symbol].forEach(el => {
        arr.push(`${el}`)
      })
    } else {
        console.log('nema')
      }
    return arr.sort((a, b) => (a === "USDT" ? -1 : b === "USDT" ? 1 : 0));
      // const pairs2 = [];
      // return coin ? pairs[coin.symbol]
      //   .forEach(el => {
      //     if(coin.symbol.toLowerCase() === 'doge') {
      //       console.log(el, coin.symbol.toLowerCase(),secondCoin.toLowerCase() )
      //     }
      //
      //     return el === `${coin.symbol.toLowerCase()}_${secondCoin.toLowerCase()}`}) : null
    }
  );
  //
   const refreshInterval = Math.floor(Math.random() * (7000 - 3000 + 1)) + 3000;
  //
  // Effects
  useEffect(() => {
    // console.log('dal sam i ovde')
    if (coin) {
        if (coin.className) {
          // console.log('i am changed', coin.curr_a, coin.className)
          setCoinClassName(coin.className)
        }
    }
  }, [coin]);


  useEffect(() => {
    // console.log(pairs)
  }, [pairs]);

  //
  useEffect(() => {
    if (coinClassName !== '') {
      setTimeout(() => {
        // console.log('Reset class', coin.curr_a);
        setCoinClassName('');
      }, 1500);
    }
  }, [coinClassName, coin.curr_a]);
  //
  useEffect(() => {

    const intervalID = setInterval(() => {
      // console.log({ pairs: `${coin.curr_a}_${secondCoin}`, index });
      dispatch(addTickerInfo({ pairs: `${coin.curr_a}_${secondCoin}`, index }));
    }, refreshInterval);

    return () => clearInterval(intervalID);
  }, [dispatch, coin.curr_a, secondCoin, index]);

  useEffect(() => {
     // dispatch(addTickerInfo({ pairs: `${coin.curr_a}_${secondCoin}`, index }));


  }, []);


  useEffect(() => {
    const tickerInfo = {
      pairs: `${coin.curr_a}_${secondCoin}`,
      index
    };
    const timeoutId = setTimeout(() => {
      dispatch(addTickerInfo(tickerInfo));
    }, 10);

    return () => clearTimeout(timeoutId);

  }, [secondCoin]);
  //
  // Memoized event handlers
  const handleSetSecondCoin = (secCoin) => {
    dispatch(setSecondCoinAction(secCoin.value));


    setSecondCoin(secCoin.value.toUpperCase());
    // dispatch(setSelectedCoin(index));
  }

  const handleAddFavorite = () => {
    dispatch(setAddFavorite(index));
    dispatch(updateDisplayedCoins())
    dispatch(setSelectedCoin())
    dispatch(updateFavoriteFlags());
  }
  // Render Component
  return (
    coin && (
      <CoinDetails
        className={`${coinClassName} ${coin.trend} ${isSelected === index ? 'selected' : ''} ${coin.isFavorite ? 'favorite' : ''}`}
        onClick={() => {
          dispatch(setSelectedCoin(index));
          dispatch(setSecondCoinAction(secondCoin));
        }}
        style={style}
      >
        {isScrolling ? 'Scrolling' : (
          <>
            <div className="coin_name">
              <div>
                <div className={`icon-16 icon-16-${coin.symbol.toLowerCase()}`}></div>
                <h3>{coin.symbol}</h3>
                <div style={{marginLeft: '3px'}} onClick={handleAddFavorite}>{
                  coin.isFavorite ? <FilledStar/> : <EmptyStar/>
                }</div>
              </div>
            </div>
            {pairs && (
              <div className="coin_pair">
                <MyComponent pairs={pairs} handleSetSecondCoin={handleSetSecondCoin} />
              </div>
            )}
            <div className="coin_rate">
              <span className="coin_rate_usdt">{coin.last ? coin.last : <ClipLoader color={'white'} size={25}/>}</span>
            </div>
          </>
        )}
      </CoinDetails>
    )
  );
  // return (
  //   <div>
  //     hello
  //   </div>
  // )

};


const areEqual2 = (prevProps, nextProps) => {


  // If the isSelected prop matches the index of the current component, re-render.
  if (prevProps.isSelected === prevProps.index || nextProps.isSelected === nextProps.index) {
    return false;
  }

  // Check other props if needed. For example:
  if (prevProps.isScrolling !== nextProps.isScrolling) {
    return false;
  }


  return true;
};

export const MemoizedCoinListDetails = React.memo(CoinListDetails, areEqual2);


