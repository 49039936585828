import { Timeline } from 'react-twitter-widgets'
import { useContext } from 'react'
import { ThemeContext } from '../../contexts/themecontext'



const TwitterTimeline = () => {

  const { th } = useContext(ThemeContext);


  return (
      <Timeline
        dataSource={{
          sourceType: 'profile',
          screenName: 'MILCplatform'
        }}
        options={{
          theme: th
        }}
      />
  );
};

export default TwitterTimeline;
