import React from "react";
import styled from "styled-components";
import SimpleBar from "simplebar-react";
import TwitterTimeline from "./TwitterTimeline";
import "simplebar-react/dist/simplebar.min.css";
import "./twitterfeed.css";

const NewsFeedTitle = styled.h3`
  font-family: ${(props) => props.theme.fonts.mainFont};
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  background-color: ${(props) => props.theme.colors.bgLayoutPart};
  padding: 10px 0;
  color: ${(props) => props.theme.colors.textPrimary};
`;

const NewsFeedContainer = styled.div`
  background-color: ${(props) => props.theme.colors.bgLayoutPart};
  font-family: Lato, sans-serif;
  padding: 15px;

  h3 {
    width: 100%;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.mainFont};
  }

  a {
    display: block;
    padding: 10px 0;
    font-size: 14px;
    line-height: 18px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default function TwitterFeed() {

  return (
    <div
      style={{
        paddingBottom: "30px",
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '50%'
      }}
    >
      <NewsFeedTitle>Twitter News</NewsFeedTitle>
      <div style={{flex: 1,
        overflow: 'hidden'}}>
        <SimpleBar style={{ maxHeight: '100%' }}>
          <TwitterTimeline />
        </SimpleBar>
      </div>

    </div>
  );
}
