import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../contexts/themecontext'


const Switcher = styled.div`
  {
      position: relative;
      border-radius: 50px;
      width: 7.2rem;
      height: 3.2rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: ${(props) => props.theme.colors.buttonBg};
      color: ${(props) => props.theme.colors.textPrimary};
      border: ${(props) => props.theme.borderStyle};
  }
  
  

  &.light {
    & .switch {
      transform: translateX(2px)
    }
  }
  
  &.dark {
    & .switch {
      transform: translateX(38px);
    }
  }
  

  & .switch {
    width: 2.7rem;
    height: 2.7rem;
    border-radius: 100%;
    position: absolute;
    transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    background-color: red;
    z-index: 0;
    position: absolute;
  }
  
  & svg {
      position: absolute;
      z-index: 1;
  }
  
  & .sun {
    left: 0;
    
    & > * {
      stroke: ${(props) => props.theme.colors.textPrimary};
    }
    
  }
  
  & .moon {
    right: 0;
    & > * {
      fill: ${(props) => props.theme.colors.textPrimary};
    }
  }
`


const ThemeSwitcher = () => {

  const { th, toggleTheme } = useContext(ThemeContext);

  return (
    <Switcher onClick={toggleTheme} className={th}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" className={'sun'}>
        <circle cx="16" cy="16" r="3.5" stroke="#F1F3F3" stroke-width="1.5"/>
        <path stroke="#F1F3F3" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="M16 8.5v-2M16 25.5v-2M10.697 10.697 9.282 9.282M22.717 22.718l-1.414-1.415M23.5 16h2M6.5 16h2M21.303 10.697l1.415-1.415M9.283 22.718l1.414-1.415"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" className={'moon'}>
        <path fill="#F1F3F3" fill-rule="evenodd"
              d="M23.489 15.582a5 5 0 1 1-6.584-7.509c.385-.304.305-1.006-.184-1.045a9 9 0 1 0 8.25 8.25c-.038-.488-.74-.568-1.044-.183a5.02 5.02 0 0 1-.438.487Zm-1.19 2.5c.409-.155.863.193.707.6a7.5 7.5 0 1 1-9.689-9.689c.408-.155.756.3.602.708a6.5 6.5 0 0 0 8.38 8.38Z"
              clip-rule="evenodd"/>
      </svg>
      <div className="switch">
      </div>
    </Switcher>
  )

}
export default ThemeSwitcher;
