import React, { useEffect, useState } from "react";
import Wrapper from "../components/Layout/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  addExtraDetails,
  fetchCoinsThunk,
  getEuroCurr,
} from "../redux/reducers/coinsSlice";
import {
  setDisplayedCoins,
  setSelectedCoin,
} from "../redux/reducers/coinsSlice";
import SingleCoinHeader from "../components/SingleCoin/SingleCoinHeader";
import SingleCoinContent from "../components/SingleCoin/SingleCoinContent";

function BtcCoinPage() {
  const dispatch = useDispatch();
  const coinsList = useSelector((state) => state.coins.coinsList);
  const coins = useSelector((state) => state.coins.displayedCoins);
  const fetchingCoins = useSelector((state) => state.coins.fetchingCoins);
  const [coinLoaded, setCoinLoaded] = useState(false);
  const [coinClassName, setCoinClassName] = useState("");
  const [refreshInterval, setRefreshInterval] = useState(
    Math.floor(Math.random() * (8000 - 6000 + 1)) + 6000
  );
  const [coinUpdated, setCoinUpdated] = useState(false);

  let intervalID = null;

  const getCoinDetails = async () => {
    const url = `http://localhost:8080/coinPairs/ETH_USDT`;
    const res = await fetch(`${url}`);
    const data = await res.json();
    if (res.ok) {
      dispatch(addExtraDetails({ coin: data, index: 0 }));
    } else {
      throw new Error("failed to fetch coins");
    }
  };

  function intervalManager(flag, time) {
    if (flag) {
      intervalID = setInterval(async () => {
        await getCoinDetails().catch(console.error); // API call
        clearInterval(intervalID);
      }, time);
    } else {
      clearInterval(intervalID);
    }
    setCoinUpdated(!coinUpdated);
  }

  useEffect(() => {
    if (Object.keys(coinsList).length === 0) {
      dispatch(fetchCoinsThunk());
      dispatch(getEuroCurr());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(coinsList).length > 0) {
      const coin = coinsList.data.find((item, index) => item.symbol === "ETH");
      dispatch(setDisplayedCoins([coin]));
      dispatch(setSelectedCoin(0));
      getCoinDetails().catch((err) => console.error(err));
    }
  }, [coinsList]);

  useEffect(() => {
    if (!coinLoaded) {
      getCoinDetails().catch(console.error);
      setCoinLoaded(true);
    }
  }, [getCoinDetails]);

  useEffect(() => {
    const int = Math.floor(Math.random() * (8000 - 6000 + 1)) + 6000;
    intervalManager(false);
    if (coinLoaded) {
      setRefreshInterval(int);
      intervalManager(true, refreshInterval);
    }
    setTimeout(() => {
      setCoinClassName("");
    }, 1000);
  }, [coins]);

  return (
    <Wrapper style={{ height: "100vh" }}>
      <div className="flex-wrap">
        <SingleCoinHeader />
        <SingleCoinContent />
      </div>
    </Wrapper>
  );
}

export default BtcCoinPage;
