import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import backendUrl from '../../config';
import { determineClassName } from '../../helpers/helpers'

export const fetchCoinsThunk = createAsyncThunk("fetchCoinResults", async () => {
  const res = await fetch(`${backendUrl}/coinList`);

  const data = await res.json();

  if (res.ok) {
    return data;
  } else {
    throw new Error("failed to fetch coins");
  }
});

export const fetchCoinTickersThunk = createAsyncThunk("coins/fetchTickers", async () => {
  const res = await fetch(`${backendUrl}/tickerList`);

  const data = await res.json();

  if (res.ok) {
    return data;
  } else {
    throw new Error("failed to coins/fetchTickers");
  }
});


export const getEuroCurr = createAsyncThunk("getUsdToEuro", async () => {
  const url =
    "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usdt/eur.json";
  const res = await fetch(`${url}`);
  const data = await res.json();
  if (res.ok) {
    return data.eur;
  } else {
    throw new Error("failed to fetch currency");
  }
});

const coinsSlice = createSlice({
  name: "coins",
  initialState: {
    coinsList: {},
    coinsTicker: {},
    usdEuroRate: 0,
    displayedCoins: [],
    interval: 15,
    fetchingCoins: false,
    selectedCoin: {},
    secondCoin: "USDT",
    selectedCoinIndex: null,
    trend: "",
    graphType: "exroom",
    favorites: [],
    dummyRefresh: false
  },
  reducers: {
    hydrate: (state, action) => {
      return action.payload;
    },
    hydrateFavoritesFromLocalStorage: (state) => {
      const savedFavorites = localStorage.getItem('favorites');
      if (savedFavorites) {
        const favoriteIds = JSON.parse(savedFavorites);
        const realDisplayedCoins = [...state.displayedCoins]; // convert the proxy to a real array
        console.log(savedFavorites, 'tu smo bre', realDisplayedCoins)
        state.favorites = state.displayedCoins.filter(coin => favoriteIds.includes(coin.no));
      }
    },
    setDisplayedCoins: (state, action) => {
      if (action.payload) {
        // Get favoriteIds from favorites state
        const favoriteIds = state.favorites.map(coin => coin.no);
        console.log('favoriteIds', favoriteIds)
        // Mark coins as favorites
        const coinsWithFavoriteStatus = action.payload.map(coin => {
          if (favoriteIds.includes(coin.no)) {
            console.log('ovaj ima', coin)
            return { ...coin, isFavorite: true };
          }
          return coin;
        });

        console.log('coinsWithFavoriteStatus', coinsWithFavoriteStatus)

        state.displayedCoins = coinsWithFavoriteStatus;
      } else {
        state.dummyRefresh = !state.dummyRefresh;
      }
    },
    updateFavoriteFlags: (state) => {
      const favoriteIds = state.favorites.map(coin => coin.no);

      state.displayedCoins.forEach(coin => {
        coin.isFavorite = favoriteIds.includes(coin.no);
      });
    },

    updateDisplayedCoins: (state) => {
      const favoriteIds = state.favorites.map(coin => coin.no);
      console.log('updejtujemo', favoriteIds, state.favorites, state.displayedCoins)
      // Combine favorites with the rest of the coins that are not in favorites
      let coins = [...state.displayedCoins.filter(coin => favoriteIds.includes(coin.no)), ...state.displayedCoins.filter(coin => !favoriteIds.includes(coin.no))];

      // Sort coins so that "isFavorite" coins come first
      coins.sort((a, b) => {
        if (a.isFavorite && !b.isFavorite) return -1;
        if (!a.isFavorite && b.isFavorite) return 1;
        return 0;
      });

      console.log(coins)

      state.displayedCoins = coins;
    },

    setGraphType: (state, action)=> {
      console.log('actionpayload', action)
      state.graphType = action.payload;
    },
    setSelectedCoin: (state, action) => {
      const index = action.payload;
      const coin = state.displayedCoins[index];
      state.selectedCoinIndex = index;
      state.selectedCoin = { ...coin };
    },
    setAddFavorite: (state, action) => {
      const index = action.payload;
      const coin = state.displayedCoins[index];
      coin.isFavorite = !coin.isFavorite

      const favoriteIndex = state.favorites.findIndex(favCoin => favCoin.no === coin.no);
      console.log('favoriteIndex', favoriteIndex)
      if (favoriteIndex !== -1) {

        state.favorites.splice(favoriteIndex, 1);
      } else {
        state.favorites = [...state.favorites, coin];

      }
      console.log('setujemo', state.favorites)
      localStorage.setItem('favorites', JSON.stringify(state.favorites.map(coin => coin.no)));
    },
    setSecondCoinAction: (state, action) => {
      const secondCoin = action.payload;
      state.secondCoin = secondCoin;
    },
    addExtraDetails: (state, action) => {
      const { coin, index } = action.payload;

      if (state.usdEuroRate !== 0) {
        coin.euros = (coin.last * state.usdEuroRate).toFixed(2);
      }

      const percentDiff =
        state.displayedCoins[index].percentChange - coin.percentChange;


      coin.className = percentDiff ? determineClassName(percentDiff) : '';


      state.displayedCoins = [
        ...state.displayedCoins.slice(0, index),
        {
          ...state.displayedCoins[index],
          ...coin,
          hasExtraDetails: true,
        },
        ...state.displayedCoins.slice(index + 1)
      ];

      if (
        state.selectedCoin !== {} &&
        state.selectedCoin.name === state.displayedCoins[index].name
      ) {
        state.selectedCoin = {
          ...state.selectedCoin,
          ...coin,
          hasExtraDetails: true,
        };
      }
    },
    addTickerInfo: (state, action) => {
      const { pairs, index } = action.payload;

      // Fetch the coin ticker directly from the current state
      const coinTicker = state.coinsTicker.find(coin => coin.currency_pair === pairs);

      if (!coinTicker) {
        console.log("Coin not found");
        return; // Exit the reducer early if coin is not found
      }

      const percentDiff = coinTicker.change_percentage - state.displayedCoins[index].change_percentage;
      coinTicker.className = percentDiff ? determineClassName(percentDiff) : '';

      // Directly mutate the state using Immer's proxy (this is not real mutation)
      Object.assign(state.displayedCoins[index], coinTicker, { hasExtraDetails: true });

      if (state.selectedCoin?.name === state.displayedCoins[index].name) {
        Object.assign(state.selectedCoin, coinTicker, { hasExtraDetails: true });
      }
    }

  },
  extraReducers: (builder) => {
    builder.addCase(fetchCoinsThunk.pending, (state, action) => {
      state.coinsList = {};
      if (!state.fetchingCoins) {
        state.fetchingCoins = true;
      }
    });

    builder.addCase(fetchCoinTickersThunk.fulfilled, (state, action) => {
      const coinsData = action.payload
      
      state.coinsTicker = coinsData

    });

    builder.addCase(fetchCoinsThunk.fulfilled, (state, action) => {
      const coins = action.payload.data;

      coins.forEach((coin, index) => {
        if (coin.symbol === "MLT") {
          coins.splice(index, 1);
          coins.unshift(coin);

          state.selectedCoin = coin;
          state.selectedCoinIndex = index;
          return null;
        }
      });

      state.coinsList = action.payload;

      if (state.fetchingCoins) {
        state.fetchingCoins = false;
      }
    });

    builder.addCase(getEuroCurr.fulfilled, (state, action) => {
      state.usdEuroRate = action.payload;
    });
  },
});

const { reducer, actions } = coinsSlice;

const {
  setDisplayedCoins,
  updateDisplayedCoins,
  setSelectedCoin,
  addExtraDetails,
  setSecondCoinAction,
  setGraphType,
  setAddFavorite,
  updateFavoriteFlags,
  addTickerInfo,
  hydrateFavoritesFromLocalStorage
} = actions;

export {
  reducer as coinsSlice,
  setDisplayedCoins,
  updateDisplayedCoins,
  updateFavoriteFlags,
  hydrateFavoritesFromLocalStorage,
  setSelectedCoin,
  addExtraDetails,
  setSecondCoinAction,
  setAddFavorite,
  setGraphType,
  addTickerInfo
};
