import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './header.css';
import Wrapper from "../Layout/Wrapper";
import UserArea from '../Header/UserArea'

const Navlink = styled.div`
    background-color: ${(props) => props.theme.colors.bgLayoutPart};
    font-family: Neuropool;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
    padding: .6rem 1.2rem;
    color: ${(props) => props.theme.colors.textPrimary};
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.textPrimary};
`

const Nav = styled.nav`
  padding: 1.5rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`

export default function Header () {
  return (
    <Wrapper>
        <div
          className="col-1-of-4 header-container">
          <img className="logo" src="/images/3d-logo-240.png"/>
        </div>

      <div
        className="col-2-of-4 header-container">
        <Nav>
          <Navlink>
            <StyledLink to="/">Home</StyledLink>
          </Navlink>
          <Navlink>
            <StyledLink to="trade">Trade</StyledLink>
          </Navlink>
          <Navlink>
            <StyledLink to="/mlt_coin">MLT</StyledLink>
          </Navlink>
        </Nav>
      </div>

      <div
        className="col-1-of-4 header-container">
        <UserArea />
      </div>
    </Wrapper>

  );
}
