import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import MltInfo from "./MltInfo";
import styled, { css } from "styled-components";
import abi from "./abi";
import Error from "./error";
import ConnectButton from '../Wallet/ConnectButton'
import Wallet from '../Wallet'
const MltWrapper = styled.div`
  height: 40%;
  background-color: ${(props) => props.theme.colors.bgLayoutPart};
  box-shadow: 3px 5px 11px rgba(0, 0, 0, 0.5);
  padding: 20px 20px 20px 30px;
  font-family: Lato, sans-serif;
  margin-top: 2rem;
  
  
  @media (max-width: 1920px) {
      height: 40%;
  }
`

const Header = styled.div`
    font-family: Neuropool, sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
`;
const Balance = styled.p`
    font-size: 1.5rem;
    margin: 1rem 0;
    font-weight: bold;
`
const FormWrapper = styled.div`
  margin: 0 auto;
  padding: 1rem 0;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Input = styled.input`
  width: 100%;
  display: block;
  outline: none;
  border-radius: ${(props) => props.theme.borderRadius};;
  border: ${(props) => props.theme.borderStyle};
  height: 40px;
  background-color: transparent;
  color: white;
  padding: 0 1rem;
  margin-bottom: 1.2rem;
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.colors.buttonRed};
  color: white;
  border: ${(props) => props.theme.borderStyle};
  outline: none;
  border-radius: ${(props) => props.theme.borderRadius};
  display: block;
  width: 190px;
  padding: 10px 30px;
  cursor: pointer;
  box-shadow: ${(props) => props.theme.shadow};
  font-family:  ${(props) => props.theme.fonts.mainFont};
`;

const LinkContainer = styled.div`
display: flex;
width: 100%;
align-items: center;
padding: 2rem 0;


h4 {
font-family: Neuropool, sans-serif;
font-size: 1.8rem;
}

p {
font-weight: 600;
margin-bottom: 1rem;
}
  img {
    margin-right: 1rem;
  }
  a {
      background-color: ${(props) => props.theme.colors.buttonRed};
      border: 1px solid ${(props) => props.theme.colors.borderColor};
      border-radius: ${(props) => props.theme.borderRadius};
      padding: 0.2rem 1rem;
      font-weight: 600;
      box-shadow: ${(props) => props.theme.shadow};
  }
  
  @media (max-width: 1920px) {
      padding: 0.5rem 0;
      
      margin-top: auto;
      h4 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      }
     p {
      display: none;
     }
      
      img {
        width: 34px;
      }
      
      a {
        font-size: 1.2rem;     
       }
  }
  
`

const FlexContainer = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;

@media (max-width: 1920px) {
      margin-top: 1rem;
  }

`

export default function SmartContract() {
  const address = "0x9506d37f70eB4C3d79C398d326C871aBBf10521d";
  const [recipientAddress, setRecpientAddress] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [signer, setSigner] = useState();
  const [provider, setProvider] = useState();
  const [balance, setBalance] = useState(0);


  const connect = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        setIsConnected(true);
        let connectedProvider = new ethers.providers.Web3Provider(
          window.ethereum
        );
        setProvider(connectedProvider);
        setSigner(connectedProvider.getSigner());

        console.log('ukljuceni smo')
      } catch (err) {
        console.log(err);
      }
    } else {
      setIsConnected(false);
    }
  };

  const validateAddress = (address) => {
    return ethers.utils.isAddress(address);
  };

  useEffect(() => {
    // connect();
    if (signer) {
      console.log('signer',signer);
      getMYbalance();
    }
  }, []);

  useEffect(() => {
    if (validateAddress(recipientAddress)) {
      console.log("valid address");
    } else {
      console.log("invalid address");
    }
  }, [recipientAddress]);

  const contract = new ethers.Contract(address, abi, signer);

  const getMYbalance = async () => {
    const signerAddress = await signer.getAddress();
    const balance = await contract.balanceOf(signerAddress);
    setBalance(ethers.utils.formatEther(balance));
    console.log(balance);
    const ts = await contract.totalSupply();
  };

  const handleWalletConnection = async () => {
    await connect();

    if (signer) {
      console.log('signer',signer);
      getMYbalance();
    }
  }

  const handleWalletDisconnect = () => {
    console.log('gasimo se')
    setIsConnected(false)
  }

  return (
    <MltWrapper>
      <Header>MLT Smart Contract</Header>
      {isConnected ? (<FormWrapper>
        <MltInfo name="media license token" />
        <Balance>Balance: {balance}</Balance>
        <Form>
          <Input
            type="text"
            placeholder="Account Address"
            onChange={(e) => setRecpientAddress(e.target.value)}
          />
          <Error />
          <Input type="text" placeholder="Amount" />
          <Button>Transfer</Button>
        </Form>
        <FlexContainer>
          <LinkContainer>
            <img src="/images/ec.png" alt=""/>
            <div>
              <h4>ERC20</h4>
              <p>0x9506d37f70eB4C3d79...</p>

              <a href="https://etherscan.io/token/0x9506d37f70eb4c3d79c398d326c871abbf10521d" target="_blank" rel="noopener noreferrer">
                Etherscan
              </a>
            </div>

          </LinkContainer>
          <LinkContainer>

            <img src="/images/bscscan.png" alt=""/>

            <div>
              <h4>BP20</h4>
              <p>0x4518231a8FDF6ac5...</p>
              <a href="https://bscscan.com/token/0x4518231a8fdf6ac553b9bbd51bbb86825b583263" target="_blank" rel="noopener noreferrer">
                BSCscan
              </a>
            </div>

          </LinkContainer>
        </FlexContainer>
      </FormWrapper>) : (
        <>
          <p style={{margin: '1rem 1rem 1.5rem 0', fontSize:'13px'}}>You need to connect your wallet first!</p>
          <Wallet isWidget={true} handleConnect={handleWalletConnection} handleDisconnect={handleWalletDisconnect}/>
        </>
        )
      }


    </MltWrapper>
  );
}
