import bgLight from "../../../images/bg_light.png";
import bgDark from "../../../images/bg_dark.png";
import bgLightPredzadnja from "../../../images/bg_light_predzadnja.png";
import bgLightZadnja from "../../../images/bg_light_zadnja.png";
import bgLightDruga from "../../../images/bg_light_druga.png";

export const light = {
  red: "#e82d2d",
  green: "#006e0a",
  blue: "#73d1eb",
  gray: "#434343",
  lightGray: "#b0b0b0",
  shadow: "2px 1px 5px 2px rgba(0, 0, 0, 0.4)",
  shadowLight: "2px 1px 5px 2px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  borderStyle: "2px solid #000",
  fonts: {
    mainFont: "Neuropool, sans-serif",
  },
  colors: {
    bgLayoutPart: "rgba(255,255,255, .8)",
    bgSelected: "#8ac3db",
    bgFavorite: "#baebff",
    textPrimary: "#000000",
    textSecondary: "#434343",
    textRed: "#e82d2d",
    textGreen: "#006e0a",
    buttonBg: 'rgba(253,253,253, .8)',
    buttonRed: '#e82d2d',
    borderColor: "#000",
    bgContainer: '#e0e7f9',
    chartBg: "#e82d2d",
    onboardingBg: '#15283a',
    onboardingTxt : '#f1f1f1'
  },
  bgImage: {
    url: bgLightDruga,
  },
};


export const dark = {
  red: "#ff4040",
  green: "#61e56e",
  blue: "#73d1eb",
  gray: "#c1c1c1",
  lightGray: "#b0b0b0",
  shadow: "2px 1px 5px 2px rgba(0, 0, 0, 0.4)",
  shadowLight: "2px 1px 5px 2px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  borderStyle: "2px solid #fff",
  fonts: {
    mainFont: "Neuropool, sans-serif",
    secondaryFont: "Lato, sans-serif"
  },
  colors: {
    bgLayoutPart: "rgba(25, 46, 63, 0.8)",
    bgSelected: "rgba(37, 118, 148,0.8)",
    bgFavorite: "#023a4e",
    textPrimary: "#ffffff",
    textSecondary: "#c1c1c1",
    textRed: "#ff4040",
    textGreen: "#61e56e",
    buttonBg: 'rgba(74,74,74, .22)',
    buttonRed: '#630E17',
    borderColor: "#fff",
    bgContainer: '#15283a',
    chartBg: "rgba(37, 118, 148,0.8)",
    onboardingBg: '#e0e7f9',
    onboardingTxt : '#333'
  },
  bgImage: {
    url: bgDark,
  },
};

