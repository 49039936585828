import React, { useState, useEffect } from 'react'
import { useWeb3Modal, useWeb3ModalState } from '@web3modal/wagmi/react'
import { useAccount, useEnsName, useBalance } from 'wagmi'
import styled from 'styled-components'

const ConnectBtn = styled.button`
  background-color: ${(props) => props.theme.colors.buttonBg};
  color: ${(props) => props.theme.colors.textPrimary};
  border: ${(props) => props.theme.borderStyle};
  width: 11.2rem;
  height: 3.2rem;
  border-radius: 3rem;
  cursor: pointer;
  
  & div {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`
const ConnectButton = ({ isWidget = false, handleConnect, handleDisconnect }) => {
  const { open } = useWeb3Modal()
  const { open:modalStatus, selectedNetworkId } = useWeb3ModalState()
  const { address, isConnecting, isDisconnected, status, connector } = useAccount()
  const { data: ensName } = useEnsName({ address })
  const { data, isError, isLoading } = useBalance({
    address: address,
    onSuccess(data) {
      if(handleConnect) {
        handleConnect()
      }
    }
    })



  // useEffect(() => {
  //   console.log(selectedNetworkId)
  //   console.log(address, isConnecting, isDisconnected, status,connector)
  //   console.log(handleDisconnect, isDisconnected)
  //
  //   if(handleDisconnect && isDisconnected) {
  //     handleDisconnect()
  //   }
  // }, [isConnecting, isDisconnected, address, status, connector])


  const handleWalletConnectOpen = async () => {
    const open2 = await open()
  }



  return (
    <>
      {
        () => {
          // if (isConnecting) return <div>Connecting…</div>
          // if (isDisconnected) return <div>Disconnected</div>
        }
      }
      {isDisconnected && (
        <ConnectBtn onClick={handleWalletConnectOpen} className={'my-sixth-step-class'}>
          {modalStatus ? (
            <div>
              <wui-loading-spinner
                color="accent-100"
                size="sm"
                style={{ '--local-color': 'var(--wui-color-accent-100)' }}
              >
              </wui-loading-spinner>
              Connecting
            </div>
          ) : (
            "Connect Wallet"
          )}
        </ConnectBtn>
      )}
      {/*<p>{status}</p>*/}
      {/*{(address && !isDisconnected) && <button onClick={() => open({ view: 'Account' })}>Open Profile modal</button>}*/}
      {(address && !isDisconnected && !isWidget) && (
        <>
          {/*<div>Connected to {ensName ?? address}</div>*/}
          <w3m-account-button />
        </>
        )}
      {/*<button onClick={() => open({ view: 'Networks' })}>Open Network Modal</button>*/}

    </>
  );
};

export default ConnectButton;
