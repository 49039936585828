import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "../../assets/css/icons.css";
import { useSelector } from "react-redux";
import Chart from "../Chart/Chart";
import TradingViewWidget from "react-tradingview-widget";
import ChartSwitcher from './ChartSwitcher'

const CoinContent = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.bgLayoutPart};
  box-shadow: 3px 5px 11px rgba(0, 0, 0, 0.5);
  padding: 20px 20px 25px 30px;
  font-family: Lato, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

function SingleCoinContent(props) {
  const selectedCoin = useSelector((state) => state.coins.selectedCoin);
  const secondCoin = useSelector((state) => state.coins.secondCoin);
  const activeChart = useSelector((state)=>state.coins.graphType);
  const { symbol } = selectedCoin;
  const selectedSymbol = symbol === "MLT" ? "MLTUSDT" : `${symbol}${secondCoin}`;


  return (
    <>
      <CoinContent>

        {activeChart === "exroom" && Object.keys(selectedCoin).length > 0 && <Chart />}
        {activeChart === "tradingview" && (
          <>
            <ChartSwitcher />
            <TradingViewWidget symbol={selectedSymbol} autosize /></>)}
      </CoinContent>
    </>
  );
}

export default SingleCoinContent;
