import React, { useState } from 'react';
import WagmiWrapper from './config/web3config'
import ConnectButton from './ConnectButton'


const Wallet = ({ isWidget = false,  handleConnect = false, handleDisconnect = false}) => {
  return (
    <>
      <WagmiWrapper>
        <ConnectButton isWidget={isWidget} handleConnect={handleConnect} handleDisconnect={handleDisconnect}/>
        {/*<w3m-button size={'sm'} />*/}

      </WagmiWrapper>
    </>
  );
};

export default Wallet;
