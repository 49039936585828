import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { MemoizedCoinListDetails } from './CoinListDetails';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components'

import './CoinList.scss'
import Onboarding from '../Onboarding/Onboarding'

const List = styled(FixedSizeList)`
  background-color: ${(props) => props.theme.colors.bgContainer};
`;

function CoinList ({ updateDisplayedCoins, coinsCount }) {
  // const coins = useSelector((state) => state.coins.displayedCoins);
  const isFetching2 = useSelector(state => state.coins.fetchingCoins)
  const [isFetching, setIsFetching] = useState(false) // NEW
  const listScrollDiv = useRef()
  const coinsList = useSelector((state) => state.coins.coinsList);
  const selectedCoinIndex = useSelector(state => state.coins.selectedCoinIndex);

  const fetchMoreCoins = () => {
    // setIsFetching(true); // Indicate that we're fetching coins

    updateDisplayedCoins().then(() => {

      setTimeout(() => {
        // setIsFetching(false);
      }, 300)
    });
  };

  const handleWheel = (event) => {
    const contentHeight = listScrollDiv.current.contentHeight;
    const scrollPos = listScrollDiv.current.state.scrollPos;
    const visibleHeight = listScrollDiv.current.visibleHeight;

    const isAtBottom = visibleHeight + scrollPos >= contentHeight;

    if (event.deltaY > 0 && isAtBottom && !isFetching) {
      fetchMoreCoins();
    }

  }

  const onScroll = () => {
    if (listScrollDiv.current && !isFetching) { // We check if the scroll div exists and if we're not already fetching

      setTimeout(() => {

        const contentHeight = listScrollDiv.current.contentHeight;
        const scrollPos = listScrollDiv.current.state.scrollPos;
        const visibleHeight = listScrollDiv.current.visibleHeight;


        if (visibleHeight + scrollPos >= contentHeight - 25) {
          fetchMoreCoins();
        }
      }, 0)

    }
  };

  function itemKey (index, data) {
    const item = data[index];
    return item ? item.curr_a : 'undefined-item-' + index;
  }

  const steps = [
    {
      target: 'body',
      content: (
        <div>
          <h3>All about us</h3>
          <svg
            height="50px"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 96 96"
            width="50px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M83.2922435,72.3864207 C69.5357835,69.2103145 56.7313553,66.4262214 62.9315626,54.7138297 C81.812194,19.0646376 67.93573,0 48.0030634,0 C27.6743835,0 14.1459311,19.796662 33.0745641,54.7138297 C39.4627778,66.4942237 26.1743334,69.2783168 12.7138832,72.3864207 C0.421472164,75.2265157 -0.0385432192,81.3307198 0.0014581185,92.0030767 L0.0174586536,96.0032105 L95.9806678,96.0032105 L95.9966684,92.1270809 C96.04467,81.3747213 95.628656,75.2385161 83.2922435,72.3864207 Z"
                fill="#000000"
              />
            </g>
          </svg>
        </div>
      ),
      placement: 'center',
      disableBeacon: true,
      styles: {
        options: {
          width: 900
        }
      }
    },
    {
      target: '.my-first-step-class',
      content: 'This is the first step!',
      placement: 'right'
    },
    {
      target: '.my-second-step-class',
      content: 'This is the second step!',
      placement: 'bottom'
    },
    {
      target: '.my-third-step-class',
      content: 'This is the third step!',
      placement: 'bottom'
    },
    {
      target: '.my-fourth-step-class',
      content: 'This is the final! step!',
      placement: 'bottom'
    }
  ];
  useEffect(() => {
    console.log('coinsList', isFetching2, coinsList)
  }, [coinsList]);


  return (<>
      <List height={1150} itemCount={500} width={'100%'} itemSize={50} useIsScrolling={false} overscanCount={5}
            className={'my-first-step-class'}>
        {({ index, isScrolling, style }) => {
          if (coinsCount > 0) {
            return <MemoizedCoinListDetails index={index} isScrolling={isScrolling} style={style}
                                            isSelected={selectedCoinIndex}/>
          }
        }
        }
      </List>
      {/*{Object.keys(coinsList).length > 0 && <Onboarding steps={steps} onStart={() => {*/}
      {/*  console.log('Starting...');*/}
      {/*}} onComplete={() => {console.log('Completed!'); }} autoStart={true}/>}*/}
      {/*<div onWheel={handleWheel} style={{minHeight:'100vh', height:'100vh'}}>*/}
      {/*<CustomScroll onScroll={onScroll} ref={listScrollDiv} heightRelativeToParent="90vh" allowOuterScroll={true}>*/}

      {/*<CoinListDiv>*/}

      {/*</CoinListDiv>*/}

      {/*</CustomScroll>*/}
      {/*</div>*/}
    </>
  )

}

export default CoinList;
