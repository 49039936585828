import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import  OnboardingContext  from '../../contexts/OnboardingContext'
import { AiOutlineQuestionCircle } from 'react-icons/ai';

const HelpButton = styled.button`
{
      position: relative;
      border-radius: 50px;
      width: 3.2rem;
      height: 3.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: ${(props) => props.theme.colors.buttonBg};
      color: ${(props) => props.theme.colors.textPrimary};
      border: ${(props) => props.theme.borderStyle};
  }
`

const OnboardingStarter = () => {

  const { startOnboarding } = useContext(OnboardingContext);

  return (
    <HelpButton onClick={startOnboarding} className={'my-seventh-step-class'}><AiOutlineQuestionCircle size={32}/></HelpButton>
  )

}
export default OnboardingStarter;
