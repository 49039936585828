import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ListPage from "./pages/ListPage";
import MltCoinPage from "./pages/MltCoinPage";
import BtcCoinPage from "./pages/BtcCoinPage";
import EthCoinPage from "./pages/EthCoinPage";
// import TradingPage from "./pages/TradingPage";
import { Layout } from "./components/Layout/Layout";

import "./assets/css/scroll.css";
import SmartContracts from "./pages/SmartContracts";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<ListPage />} />
            <Route exact path="mlt_coin" element={<MltCoinPage />} />
            <Route exact path="btc_coin" element={<BtcCoinPage />} />
            <Route exact path="eth_coin" element={<EthCoinPage />} />
            <Route exact path="smartcontract" element={<SmartContracts />} />
            {/*<Route exact path="trade" element={<TradingPage />} />*/}
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
