import React, { useState } from 'react';
import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher'
import Wallet from '../Wallet'
import OnboardingStarter from '../Onboarding/OnboardingStarter'
import styled from 'styled-components'

const Div = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
`
const UserArea = () => {
  return (
    <Div>
      <ThemeSwitcher />
      <Wallet />
      <OnboardingStarter />
    </Div>
  );
};

export default UserArea;
