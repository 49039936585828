import React, { useEffect, useRef, useState } from 'react';
import Joyride from 'react-joyride';
import { useTheme } from 'styled-components'

const Onboarding = ({ steps, onStart, onComplete, autoStart }) => {
  const [runTour, setRunTour] = useState(false);
  const joyrideRef = useRef(null);
  const theme = useTheme()

  useEffect(() => {
    setTimeout(() => {
      if (joyrideRef.current) {
        console.log(joyrideRef)
        joyrideRef.current.helpers.open();
      }
    }, 2000)
  }, [joyrideRef]);


  useEffect(() => {
    if (onStart) {
      setTimeout(() => {
        onStart();
        setRunTour(true);
      }, 3000)
    }
  }, [onStart]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === 'finished' || status === 'skipped') {
      if (onComplete) {
        onComplete();
      }
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={autoStart}
      showProgress
      steps={steps}
      ref={joyrideRef}
      debug={true}
      showSkipButton={true}
      sportlightPadding={0}
      styles={{options: {
          arrowColor: theme.colors.onboardingBg || '#e3ffeb',
          backgroundColor: theme.colors.onboardingBg || '#e3ffeb',
          overlayColor: 'rgba(0, 0, 0, 0.6)',
          textColor: theme.colors.onboardingTxt || '',
          primaryColor: theme.red || '',
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
        }}}
    />
  );
}

export default Onboarding;
