import React from "react";
import styled from "styled-components";
import * as palette from "../Layout/assets/variables";

const Container = styled.div`
  display: flex;
  // max-width: ${palette.gridWidth};
  width: 100%;
  padding: 0 calc(${palette.gutterHorizontal} * 2);
  height: ${props => (props.fullHeight ? props.fullHeight : '100%')};
  
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  @media screen and(min-width: 480px) {
    padding: 0 35px;
  }

  div[class^="col-"] {
    float: left;
    &:not(:last-child) {
      margin-right: ${palette.gutterHorizontal};
    }
  }

  .col-1-of-4 {
    width: calc((100% - ${palette.gutterHorizontal} * 3) / 4);
  }
  
  .col-2-of-4 {
    width: calc(2 * ((100% - ${palette.gutterHorizontal} * 3) / 4) + ${palette.gutterHorizontal} * 2);
  }
  
  .col-3-of-4 {
    width: calc(3 * ((100% - ${palette.gutterHorizontal} * 1) / 4) + ${palette.gutterHorizontal} * 2);
  }

  .col-1-of-3 {
    width: calc((100% - 30px * 2) / 3);
  }
  .col-2-of-3 {
    width: calc(2 * ((100% - 120px * 2) / 3) + ${palette.gutterHorizontal} * 2);
  }
`;

const Wrapper = ({ children, fullHeight = '100%' }) => {
  return <Container fullHeight={fullHeight}>{children}</Container>;
};

export default Wrapper;
