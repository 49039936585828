import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import "../../assets/css/icons.css";
import { useSelector } from "react-redux";
import { nFormatter } from "../../helpers/helpers";
import { ThemeContext } from "../../contexts/themecontext";
import { Link } from "react-router-dom";

const CoinHeader = styled.div`
  background-color: ${(props) => props.theme.colors.bgLayoutPart};
  box-shadow: 3px 5px 11px rgba(0, 0, 0, 0.5);
  padding: 20px 20px 20px 30px;
  width: 100%;
  font-family: Lato, sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 82px;
  margin-bottom: 25px;

  &.down {
    .coin_rate_val {
      color: ${(props) => props.theme.red};
    }

    .coin_percent_change {
      color: ${(props) => props.theme.red};
    }
  }

  &.up {
    .coin_rate_val {
      color: ${(props) => props.theme.green};
    }

    .coin_percent_change {
      color: ${(props) => props.theme.green};
    }
  }

  & .coin_name {
    display: flex;
    align-items: center;
  }
  & .coin_name > div {
    display: flex;
    flex-direction: column;
  }

  & .coin_name .icon-32 {
    margin-right: 20px;
    width: 32px;
    height: 32px;
    display: block;
  }

  & .coin_name .coin_currency {
    font-family: Neuropool, sans-serif;
    font-size: 26px;
    letter-spacing: 1px;
    margin-bottom: 3px;
  }

  & .coin_name .coin_realName {
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    color: #c1c1c1;
  }

  .price_block {
    display: flex;
    color: #c1c1c1;
    &--middle {
      font-size: 24px;
      align-self: flex-start;
      margin-top: 3px;
    }

    & .coin_rate {
      padding-right: 25px;
      border-right: 1px solid white;
    }

    & .coin_changes {
      margin-left: 25px;
    }
  }

  & .coin_price_dual {
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    font-size: 18px;
    &:not(:last-child) {
      border-right: 1px solid #c1c1c1;
    }
  }

  & .coin_price_dual p {
    padding-right: 20px;
  }

  & .coin_price_dual > span {
    margin-top: 8px;
  }
  & .currency_sign {
    margin-right: 7px;
  }
`;

function SingleCoinHeader(props) {
  const coin = useSelector((state) => state.coins.selectedCoin);
  const { toggleTheme } = useContext(ThemeContext);

  return (
    <>
      {/* {Object.keys(coin).length > 0 ? (
        <CoinHeader className={coin.trend}>
          <div className={`coin_name`}>
            <span className={`icon-32 icon-32-${coin.symbol.toLowerCase()}`}></span>
            <div>
              <span className="coin_currency">
                {coin.curr_a}/{coin.curr_b}
              </span>
              <h3 className="coin_realName">{coin.name}</h3>
            </div>
          </div>
          <div className={`price_block price_block--middle`}>
            <span className="coin_rate">
              <span className="currency_sign">&#36;</span>
              <span className="coin_rate_val">{coin.last}</span>
            </span>
            <div className="coin_changes">
              <span className="coin_percent_change">{coin.percentChange}%</span>
              <div className="coin_rate_change"></div>
            </div>
          </div>
          <div className="price_block">
            <div className="coin_price_dual">
              <p>Highest price</p>
              <hr />
              <span>
                <span className="currency_sign">&#36;</span>
                {coin.high24hr}
              </span>
            </div>
            <div className="coin_price_dual">
              <p>Lowest price</p>
              <hr />
              <span>
                <span className="currency_sign">&#36;</span>
                {coin.low24hr}
              </span>
            </div>
            <div className="coin_price_dual">
              <p>24H Volume</p>
              <hr />
              <span>
                <span className="currency_sign">&#36;</span>
                {nFormatter(coin.baseVolume)}
              </span>
            </div>
          </div>
        </CoinHeader>
      ) : (
        <CoinHeader></CoinHeader>
      )} */}
      <div>
        <button onClick={toggleTheme}>Dark mode</button>
      </div>
    </>
  );
}

export default SingleCoinHeader;
