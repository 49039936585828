import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { mainnet, bsc } from 'wagmi/chains';
import { useContext, useEffect } from 'react'
import { ThemeContext } from '../../../contexts/themecontext'
import { useAccount } from 'wagmi'
import { WagmiConfig } from 'wagmi'

const projectId = '536b836b2b64873c88095f7c0c8ebde0';

const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
};

const chains = [mainnet, bsc];

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });


const web3Modal = createWeb3Modal({ wagmiConfig,
  projectId,
  chains,
  themeMode: 'dark',
  featuredWalletIds: [
   '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709'
  ],
  themeVariables: {
      '--w3m-accent': '#b82d2d',
  }
});


export default function WagmiWrapper({children}) {

  useEffect(() => {
    return () => {
    };
  }, []);
  
  return (
    <WagmiConfig config={wagmiConfig}>
      {children}
    </WagmiConfig>
  )
}
