import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCoinTickersThunk } from '../../redux/reducers/coinsSlice'; // Adjust the path accordingly

function TickerUpdater() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch the thunk immediately once when the component mounts
    dispatch(fetchCoinTickersThunk());

    // Set up the interval to dispatch the thunk every second
    const intervalId = setInterval(() => {
      dispatch(fetchCoinTickersThunk());
    }, 3000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch]);

  return null; // This component doesn't render anything
}

export default TickerUpdater;
